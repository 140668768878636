<template>
  <div class="pabulum">
    <p class="title">营养</p>
    <p class="info">生活中有不少保护眼睛的方法，饮食就是其中的一种。那么你知道吃什么食物可以保护眼睛。</p>
    <p class="brief">保护眼睛的食物：</p>
    <div class="scrollView">
      <div class="container">
        <div class="conItem" v-for="item in list">
          <div class="left">
            <img :src="item.imgSrc" alt="">
          </div>
          <div class="right">
            <div class="tips">{{item.tips}}</div>
            <div class="tipsContent">{{item.tipsContent}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="btnGroup" >
      <button @click="go" style="display: none">返回</button>
    </div>

    <div>
    </div>
  </div>
</template>

<script>
export default {
  name: "pabulum",
  data() {
    return {
      list: [],
      content: '',
      imgSrc: '',
      flag : -1
    }
  },
  methods: {
    go() {
      this.$router.go(-1);
    }
  },
  created() {
    let arr = [
      {
        imgSrc: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/Resource/WebDemo/pic/leek.jpg',
        tips: '韭菜',
        tipsContent: '韭菜含有很多的维A原、维C、还含有蛋白质、钙、纤维素、磷、挥发油、铁和脂肪等。此外，韭菜还含有抗生物质，具有调味、杀菌的功效'
      },
      {
        imgSrc: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/Resource/WebDemo/pic/spinach.jpg',
        tips: '菠菜',
        tipsContent: '吃大量菠菜，也许可减低老年失明的危险。这要归功于类胡萝卜素——绿色多叶蔬菜和黄色蔬菜中的色素。菠菜有助于提高人的记忆力，它含有的抗氧化剂可对人体有害分子起阻挡作用。中医学认为菠菜根可治便秘、痔疮出血和夜盲症，也认定对视力有很好的辅助疗效'
      },
      {
        imgSrc: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/Resource/WebDemo/pic/carrot.jpg',
        tips: '胡萝卜',
        tipsContent: '胡萝卜跟眼睛的关系主要来自于它的胡萝卜素，因为胡萝卜素在人体之内可转化成维生素A，维生素A对眼睛的视力很有帮助，特别在暗光下和光线快速变换下的视力，一定要维生素A参与才能顺利完成，人们现在经常盯着电脑看，看电视，打游戏，这时消耗的维生素A特别多，很多人会感觉眼睛发干，如果膳食中缺维生素A就会有这种不良感觉，所以应多补充胡萝卜素，吃胡萝卜可以明目就是这个道理'
      },
      {
        imgSrc: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/Resource/WebDemo/pic/apricot.jpg',
        tips: '杏',
        tipsContent: '杏含有适量的维C及丰富的维A原，还含糖、蛋白质、无机盐、维B1维B2、脂肪等，是一种营养价值较高的水果。'
      },
      {
        imgSrc: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/Resource/WebDemo/pic/jujube.jpg',
        tips: '枣',
        tipsContent: '枣营养十分丰富，含有对人体有益的14种氨基酸，维他命含量高出苹果、香蕉几十倍，有“活维他命丸”的美称。'
      },
      {
        imgSrc: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/Resource/WebDemo/pic/tomatoes.jpg',
        tips: '西红柿',
        tipsContent: '西红柿含有丰富的维他命、矿物质、碳水化合物、有机酸及少量的蛋白质。因带酸性，所有有保护维C的作用，烹煮过程中不易破坏'
      },
      {
        imgSrc: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/Resource/WebDemo/pic/greenpepper.jpg',
        tips: '青椒',
        tipsContent: '含有丰富的维生素，糖类、纤维质、钙、磷、铁等营养素，是蔬菜中维生素C含量最高的。'
      },
      {
        imgSrc: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/Resource/WebDemo/pic/cherry.jpg',
        tips: '樱桃',
        tipsContent: '经常坐在电脑前，由于眼睛过久地注视屏幕，会使视网膜上的感光物质消耗过多，如果不能及时补充维生素A和相关营养素，容易导致眼痛、视力下降、怕光等症状，甚至诱发夜盲症。据测定，每100克新鲜樱桃中维生素A的含量比苹果、葡萄等水果高4——5倍。另外，樱桃的含铁量居水果之首，而铁又是血红蛋白的原料，非常适合受到电脑辐射影响的女性食用'
      },
      {
        imgSrc: 'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/Resource/WebDemo/pic/kelp.jpg',
        tips: '海带',
        tipsContent: '海带除含碘外还含有1/3的甘露醇，晒干的海带表面有一层厚厚的“白霜”，它就是海带中的甘露醇，甘露醇有利尿作用，可减轻眼内压力，用来治疗急性青光眼有良好的功效。其他海藻类如裙带菜也含有甘露醇，也可用来作为治疗急性青光眼的辅助食品。'
      }
    ];
    this.list = arr;
  },
}
</script>

<style scoped lang="scss">
.pabulum {
  position: relative;
  width: 100%;
  height: 100%;
  background: url("http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/Resource/WebDemo/pic/pedia_background.jpg") no-repeat center center;
  background-size: cover;
}
.title {
  line-height: 40px;
  font-weight: 600;
  font-size: 18px;
}
.brief {
  margin: 25px 0;
  text-indent: 4em;
  font-size: 16px;
  color: rgb(255,120,0);
  text-align: left;
}
.scrollView {
  margin: 0 auto;
  height: 70vh;
  width: 90%;
  overflow: hidden;
  overflow-y:scroll;
}
.container {
  width: 100%;
  height: 100%;
}
.conItem {
  height: 200px;
  display: flex;
  border-bottom: 1px solid #999;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.conItem .left {

}
.conItem img{
  width: 150px;
  height: 150px;
}
.conItem .right {
  height: 150px;
  margin-left: 25px;
}
.tips {
  text-align: left;
  font-weight: 600;
}
.tipsContent {
  margin-top: 15px;
  padding-right: 20px;
  text-align: left;
}
.btnGroup {
  width: 100%;
  position: absolute;
  bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btnGroup button {
  margin: 15px 30px;
  padding: 10px 80px;
  background: transparent;
  border: 1px solid #333;
  color: #333;
  ont-size: 18px;
}

</style>